import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Card,
  InputBase,
  Divider,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  getUsersList,
  getDepartments,
  createAodmUser,
  deleteAodmUser,
  updateAodmUser,
} from "../../utils/api";
import useAlerts from "src/components/Alerts";
import { SelectChangeEvent } from "@mui/material/Select";
import DeleteDialog from "src/components/DeleteDialog";

interface User {
  id: number;
  name: string;
  email: string;
  username: string;
  password: string;
  password_confirm: string;
  dept_ids: string;
  role: string;
  [key: string]: any; // This allows indexing with a string
}

interface FormValues {
  name: string;
  email: string;
  username: string;
  password: string;
  password_confirm: string;
  dept_ids: string;
  role: string;
}

type UserUpdate = Partial<FormValues> & { departments?: any[] };
const Users: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { showAlert } = useAlerts();
  const [openDialog, setOpenDialog] = useState(false);
  const [usersList, setUsersList] = useState<any>([]);
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingUser, setEditingUser] = useState<any>(null);
  const [editingUserId, setEditingUserId] = useState<number | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const initialFormValues = {
    name: "",
    email: "",
    username: "",
    password: "",
    password_confirm: "",
    dept_ids: "",
    role: "", //aodm or aodm_admin
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const handleBackButton = () => {
    navigate("/configuration");
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const checkPasswordsMatch = () => {
    return formValues.password === formValues.password_confirm;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const fetchUsers = async () => {
    let cacheBuster = new Date().getTime();
    try {
      const users = await getUsersList(cacheBuster);
      setUsersList(users);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };
  const fetchDepartments = async () => {
    try {
      const departments = await getDepartments();
      setDepartmentList(departments);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchDepartments();
  }, []);

  const handleSubmit = async () => {
    try {
      if (
        formValues.name &&
        formValues.email &&
        formValues.username &&
        formValues.password &&
        formValues.password_confirm &&
        formValues.dept_ids &&
        formValues.role &&
        checkPasswordsMatch()
      ) {

        // Attempt to create a new user
        const newUser = await createAodmUser(formValues);

        if (newUser) {
          showAlert(
            `User ${newUser?.user?.fname ?? ""} created successfully`,
            "success"
          );
          setFormValues(initialFormValues); // Clear the form
          fetchUsers(); // Refresh users list
          handleClose();
        } else {
          showAlert(`User creation failed!`, "error");
        }
      } else {
        if (!checkPasswordsMatch())
          showAlert(
            "Passwords do not match. Please try again.",
            "warning"
          );
        else showAlert("Please fill all fields correctly.", "warning");
      }
    } catch (error) {
      // Handle specific API error for duplicate email and username
      if (error.response && error.response.data && error.response.data.errors) {
        const apiErrors = error.response.data.errors;
        if (apiErrors.includes("The email has already been taken.")) {
          showAlert("Email already exists. Please use a different email.", "warning");
          return;
        }

        if (apiErrors.includes("The username has already been taken.")) {
          showAlert("Username already exists. Please use a different username.", "warning");
          return;
        }

      }
      // Handle any errors that occur during the user creation process
      console.error("Error creating user:", error);
      showAlert(`User creation failed!`, "error");
    }
  };

  // const handleDeleteUser = async (userId: number) => {
  //   try {
  //     await deleteAodmUser(userId);
  //     showAlert("User deleted successfully", "success");
  //     setUsersList(usersList.filter((user: any) => user.id !== userId));
  //     setDeleteDialogOpen(false);
  //   } catch (error) {
  //     console.error("Error deleting user:", error);
  //     showAlert("Failed to delete user", "error");
  //   }
  // };

  const handleDeleteUser = async () => {
    if (userToDelete !== null) {
      try {
        // console.log("Deleting user with ID:", userToDelete);
        await deleteAodmUser(userToDelete);
        showAlert("User deleted successfully", "success");
        setUsersList(usersList.filter((user: any) => user.id !== userToDelete));
        setDeleteDialogOpen(false);
        setUserToDelete(null); // Clear userToDelete after deletion
      } catch (error) {
        console.error("Error deleting user:", error);
        showAlert("Failed to delete user", "error");
      }
    }
  };
  

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleEditClick = (user: any) => {
    setIsEditing(true);
    setEditingUserId(user.id);
    setFormValues({
      name: user.fname + " " + user.lname,
      email: user.email,
      username: user.username,
      password: "",
      password_confirm: "",
      dept_ids: user.departments?.[0]?.id.toString() || "",
      role: user.roleType,
    });
  };

  // const handleSaveClick = async () => {
  //   if (editingUserId !== null) {
  //     try {
  //       await updateAodmUser({
  //         ...formValues,
  //         user_id: editingUserId,
  //         name: formValues.name, // Use the full name directly
  //       });
  //       showAlert("User updated successfully", "success");
  //       setUsersList(
  //         usersList.map((user: any) =>
  //           user.id === editingUserId
  //             ? {
  //               ...user,
  //               name: formValues.name,
  //               email: formValues.email,
  //               username: formValues.username,
  //               roleType: formValues.role,
  //               departments: departmentList.filter(
  //                 (dept: any) => dept.id.toString() === formValues.dept_id
  //               ),
  //             }
  //             : user
  //         )
  //       );
  //       setEditingUserId(null);
  //       setFormValues(initialFormValues);
  //       handleClose();
  //     } catch (error) {
  //       showAlert("Failed to update user", "error");
  //     }
  //   }
  // };

  const handleSaveClick = async () => {
    if (editingUserId !== null) {
      try {
        const originalUser = usersList.find((user: User) => user.id === editingUserId);
  
        if (!originalUser) {
          showAlert("User not found", "error");
          return;
        }
  
        const updatedFields: UserUpdate = {};
  
        (Object.keys(formValues) as (keyof FormValues)[]).forEach((key) => {
          if (formValues[key] !== originalUser[key]) {
            updatedFields[key] = formValues[key];
          }
        });
  
        // Update department field separately if it has changed
        const updatedDepartments = departmentList.filter(
          (dept: any) => dept.id?.toString() === formValues.dept_ids
        );
  
        // If department field changed, add it to the update payload
        if (originalUser.departments?.[0]?.id.toString() !== formValues.dept_ids) {
          updatedFields.departments = updatedDepartments;
        }
  
        if (Object.keys(updatedFields).length > 0) {
          await updateAodmUser({
            ...updatedFields,
            user_id: editingUserId,
          });
          showAlert("User updated successfully", "success");
  
          setUsersList(
            usersList.map((user: User) =>
              user.id === editingUserId
                ? {
                    ...user,
                    ...updatedFields,
                    departments: updatedFields.departments || user.departments, // Ensure departments are updated locally
                  }
                : user
            )
          );
        }
  
        setEditingUserId(null);
        setFormValues(initialFormValues);
        handleClose();
      } catch (error) {
        showAlert("Failed to update user", "error");
      }
    }
  };

  const handleCancelClick = () => {
    setEditingUserId(null);
    setFormValues(initialFormValues);
    setOpenDialog(false);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<
      HTMLInputElement | { name?: string; value: unknown }
    >
  ) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name as string]: value,
    });
  };

  const handleDeleteClick = (userId: any) => {
    setUserToDelete(userId);
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setUserToDelete(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = usersList
    .filter((row: any) => {
      return Object.values(row).some(
        (value: any) =>
          value?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
          row.id?.toString().includes(searchQuery) // Include the id field in the search
      );
    });

  return (
    <Box sx={{ padding: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginLeft: "70px",
          }}
          onClick={handleBackButton}
        >
          <Icon
            icon="mdi:arrow-left"
            style={{
              fontSize: 74,
              color: theme.palette.obs.main,
              marginRight: 8,
            }}
          />
          <Typography variant="h6" sx={{ color: theme.palette.obs.main }}>
            Configuration Home Page
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<Icon icon="mdi:plus-circle-outline" />}
          sx={{
            backgroundColor: theme.palette.obs.main,
            color: "#fff",
            borderRadius: "8px",
            textTransform: "none",
          }}
          onClick={handleClickOpen}
        >
          Add User
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        style={{
          maxWidth: "88%",
          margin: "auto",
          padding: "0 20px",
          marginLeft: "80px",
          marginTop: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Typography variant="h6" color={theme.palette.obs.main}>
            <b> No. of Users: {usersList?.length ?? 0}</b>
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "2px 10px",
              width: "300px",
            }}
          >
            <Icon icon="mdi:search" />
            <InputBase
              placeholder="Search users"
              inputProps={{ "aria-label": "search" }}
              sx={{ ml: 1, width: "100%" }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Box>
        </Box>
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                USER ID
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                NAME
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                USERNAME
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                EMAIL ID
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                ROLE
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              >
                DEPARTMENT
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", color: theme.palette.obs.main }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.map((user: any, index: any) => (
              <TableRow key={index}>
                <TableCell>
                  {user.id}
                </TableCell>
                <TableCell>
                  {editingUserId === user.id ? (
                    <TextField
                      value={formValues.name}
                      name="name"
                      onChange={handleTextFieldChange}
                      autoFocus
                    />
                  ) : (
                    user.name
                  )}
                </TableCell>
                <TableCell>
                  {editingUserId === user.id ? (
                    <TextField
                      value={formValues.username}
                      name="username"
                      onChange={handleTextFieldChange}
                    />
                  ) : (
                    user.username
                  )}
                </TableCell>
                <TableCell>
                  {editingUserId === user.id ? (
                    <TextField
                      value={formValues.email}
                      name="email"
                      onChange={handleTextFieldChange}
                    />
                  ) : (
                    user.email
                  )}
                </TableCell>
                <TableCell>
                  {editingUserId === user.id ? (
                    <FormControl fullWidth>
                      <InputLabel>Role</InputLabel>
                      <Select
                        name="role"
                        value={formValues.role}
                        onChange={handleSelectChange}
                        sx={{ width: '200px' }}
                      >
                        <MenuItem value="aodm">AODM</MenuItem>
                        <MenuItem value="aodm_admin">AODM Admin</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    user.roleType
                  )}
                </TableCell>
                <TableCell>
                  {editingUserId === user.id ? (
                    <FormControl fullWidth>
                      <InputLabel>Departments</InputLabel>
                      <Select
                        name="dept_ids"
                        value={formValues.dept_ids}
                        onChange={handleSelectChange}
                      >
                        {departmentList?.map((dept: any, index: number) => (
                          <MenuItem key={index} value={dept.id?.toString()}>
                            {dept?.dept_name??'N.A'}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    user?.departments?.map((dept: any) => dept?.dept_name??'N.A').join(", ")
                  )}
                </TableCell>

                <TableCell
                  sx={{ display: "flex", justifyContent: "center", gap: 2 }}
                >
                  {editingUserId === user.id ? (
                    <>
                      <IconButton onClick={handleSaveClick}>
                        <Icon
                          icon="mdi:check"
                          style={{ fontSize: 24, color: "green" }}
                        />
                      </IconButton>
                      <IconButton onClick={handleCancelClick}>
                        <Icon
                          icon="mdi:close"
                          style={{ fontSize: 24, color: "red" }}
                        />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton onClick={() => handleEditClick(user)}>
                        <Icon
                          icon="mdi:pencil"
                          style={{ fontSize: 24, color: "#5a6e8c" }}
                        />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteClick(user.id)}>
                        <Icon
                          icon="mdi:delete"
                          style={{ fontSize: 24, color: "#e53935" }}
                        />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DeleteDialog
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
        onConfirm={handleDeleteUser}
        title="Confirm Deletion"
        description="Are you sure you want to delete this User?"
      />

      <Dialog
        open={openDialog}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 20,
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Create User
          </Typography>
          <IconButton onClick={handleClose}>
            <Icon icon="mdi:close" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card sx={{ borderRadius: 2, p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Name"
                      name="name"
                      fullWidth
                      required
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Enter Name"
                      value={formValues.name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Email ID"
                      name="email"
                      fullWidth
                      required
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Enter email ID"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Username"
                      name="username"
                      fullWidth
                      required
                      margin="dense"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Enter username"
                      value={formValues.username}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Password"
                      name="password"
                      fullWidth
                      required
                      margin="dense"
                      type="password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Enter password"
                      value={formValues.password}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Confirm Password"
                      name="password_confirm"
                      fullWidth
                      required
                      margin="dense"
                      type="password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Confirm password"
                      value={formValues.password_confirm}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ borderRadius: 2, p: 2 }}>
                <Box
                  sx={{
                    border: "1px dashed #6c8ebf",
                    borderRadius: "8px",
                    padding: 2,
                    mb: 2,
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                    Assign Department
                  </Typography>
                  <TextField
                    select
                    label="Department *"
                    name="dept_ids"
                    fullWidth
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Select Department"
                    value={formValues.dept_ids}
                    onChange={handleChange}
                  >
                    {departmentList?.map((dept: any) => (
                      <MenuItem key={dept.id} value={dept.id}>
                        {dept.dept_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box
                  sx={{
                    border: "1px dashed #6c8ebf",
                    borderRadius: "8px",
                    padding: 2,
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                    Assign Role
                  </Typography>
                  <TextField
                    select
                    name="role"
                    label="Role *"
                    fullWidth
                    margin="dense"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Select Role"
                    value={formValues.role}
                    onChange={handleChange}
                  >
                    <MenuItem key="1" value="aodm">
                      AODM
                    </MenuItem>
                    <MenuItem value="aodm_admin">AODM Admin</MenuItem>
                  </TextField>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6c8ebf",
                color: "#fff",
                borderRadius: "8px",
                textTransform: "none",
                padding: "16px 32px",
                fontSize: "18px",
              }}
              onClick={handleSubmit}
            >
              CREATE USER
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Users;
